<template>
  <div>
    <EditDialog :isShow.sync="isDialog">
      <template v-slot:title>
        <span style="color: #409eff">{{ form.id ? '编辑' : '新增' }}任务</span>
      </template>
      <template v-slot:content>
        <el-form v-loading="loading" label-width="7em" ref="form" :model="form" :rules="formRules">
          <el-form-item label="" prop="isUnitPriceTask">
            <el-radio
              v-model="form.isUnitPriceTask"
              @input="onInput"
              :label="false"
              :disabled="disabled"
              >基于具体任务</el-radio
            >
            <el-radio
              v-model="form.isUnitPriceTask"
              @input="onInput"
              :label="true"
              :disabled="disabled"
              >基于具体单价</el-radio
            >
          </el-form-item>
          <el-form-item label="任务名称" prop="taskName">
            <el-input
              v-model="form.taskName"
              :disabled="disabled"
              autosize
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务内容" prop="taskContent">
            <el-input v-model="form.taskContent" :disabled="disabled" type="textarea"></el-input>
          </el-form-item>
          <template v-if="form.isUnitPriceTask === false">
            <el-form-item
              label="任务价格"
              style="width: 33%"
              :rules="getValidationRules('价格')"
              prop="taskPrice"
            >
              <el-input v-model="form.taskPrice" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item
              v-if="form.id"
              label="任务进度"
              style="width: 33%"
              :rules="getValidationRules('进度', true)"
              prop="taskProgress"
            >
              <el-input v-model="form.taskProgress" :disabled="priceDisabled"></el-input>
            </el-form-item>
          </template>
          <template v-if="form.isUnitPriceTask === true">
            <el-form-item
              label="任务单价"
              style="width: 33%"
              :rules="getValidationRules('单价')"
              prop="taskUnitPrice"
            >
              <el-input v-model="form.taskUnitPrice" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item
              label="计件单元"
              style="width: 33%"
              :rules="getValidationRules('单元')"
              prop="quantityCompleted"
            >
              <el-input v-model="form.quantityCompleted" :disabled="priceDisabled"></el-input>
            </el-form-item>
          </template>
          <el-form-item label="预计完成时间" style="width: 33%" prop="completeDate">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              :disabled="disabled"
              v-model="form.completeDate"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="所属部门" style="width: 49%" prop="departmentId">
            <el-select
              v-model="form.departmentId"
              :clearable="false"
              placeholder="请选择所属部门"
              @change="onDeptSelectFn"
              :disabled="disabled"
            >
              <el-option
                v-for="item in deptList"
                :key="item.id"
                :label="item.deptName"
                :value="item.deptId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行人" style="width: 49%" prop="executorList">
            <el-select
              v-model="form.executorList"
              :clearable="false"
              multiple
              :disabled="userDisabled"
              @change="onUserSelectFn"
              placeholder="请选择人员"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.memberName"
                :value="item.memberId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button @click="onSubmit" :loading="loading" type="success">保存</el-button>
        <el-button type="info" plain :loading="loading" @click="cancellationFn">取消</el-button>
      </template>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AssigningTask',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isAuditShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      form: {},
      formRules: {
        isUnitPriceTask: [
          {
            required: true,
            message: '请选择任务类型',
            trigger: ['blur', 'change'],
          },
        ],
        taskName: [
          {
            required: true,
            message: '请输入任务名称',
            trigger: ['blur', 'change'],
          },
        ],
        taskContent: [
          {
            required: true,
            message: '请输入任务内容',
            trigger: ['blur', 'change'],
          },
        ],
        departmentId: [
          {
            required: true,
            message: '请选择所属部门',
            trigger: ['blur', 'change'],
          },
        ],
        executorList: [
          {
            required: true,
            message: '请选择执行人员',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal && this.options) {
          this.form = this.options.deepClone()
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      projectDeptMemberAndManagerList: state => state.project.projectDeptMemberAndManagerList,
    }),
    userList() {
      return this.projectDeptMemberAndManagerList.filter(item => item.status == 1)
    },
    deptList() {
      let arr = []
      if (this.projectInformation && this.projectInformation.projectDeptList) {
        arr = this.projectInformation.projectDeptList
      }
      return arr
    },
    disabled() {
      let show = false
      if (this.form.isPass == 1) {
        show = true
      } else {
        show = false
      }
      return show
    },
    priceDisabled() {
      let show = false
      if (
        (this.options.parentId == 0 && this.options.isPass != 1) ||
        this.options.parentId != 0 ||
        (this.options.parentId == 0 && this.options.children.length == 0) ||
        this.options.isPass == 0
      ) {
        show = false
      } else {
        show = true
      }
      return show
    },
    userDisabled() {
      let show = false
      if (
        !this.options.id ||
        (this.options.parentId == 0 && this.options.type) ||
        (this.options.parentId == 0 && this.options.children.length == 0 && this.options.type)
      ) {
        show = false
      } else {
        show = true
      }
      return show
    },
    isBtn() {
      let show = false
      if (this.options && this.options.operatingButton) {
        show = this.options.operatingButton.some(r => {
          return r == 'EDIT'
        })
      }
      return show
    },
  },
  filters: {},
  created() {},
  mounted() {},
  methods: {
    onDeptSelectFn(val) {
      const selectedUsers = this.deptList.filter(item => val.includes(item.deptId))
      this.form.department = selectedUsers.map(user => user.deptName).join(',')
    },
    onUserSelectFn(val) {
      const selectedUsers = this.userList.filter(item => val.includes(item.memberId))
      this.form.executor = selectedUsers.map(user => user.memberName).join(',')

      if (!this.isAuditShow && this.form.isProcess == 1) {
        this.options.nonDelExecutorList.forEach(item => {
          if (val.indexOf(item) == -1) {
            val.push(item)
          }
        })
      }
    },
    onInput() {
      if (this.form.isUnitPriceTask) {
        this.form.taskUnitPrice = 0
        this.form.quantityCompleted = 0
      } else {
        this.form.taskPrice = 0
        this.form.taskProgress = 0
      }
    },
    onSubmit() {
      if (this.isAuditShow) {
        this.$emit('updateAudit', this.form)
        this.$emit('update:isShow', false)
      } else {
        this.$refs.form.validate(async valid => {
          if (valid) {
            this.loading = true
            this.form.taskProgress = this.form.taskProgress / 100
            const res = await this.$api.project.addOrUpdateTaskAssgin(this.form)
            if (res.code == 200) {
              this.$message.success('操作成功')
            } else {
              this.$message.error('操作失败')
            }
            this.$emit('update:isShow', false)
            this.$emit('onUpdate')
            this.loading = false
          }
        })
      }
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },
    getValidationRules(str = '', isShow = false) {
      return [
        {
          required: true,
          message: '请输入' + str,
          trigger: ['blur', 'change'],
        },
        {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: str + '必须为数值',
          trigger: ['blur', 'change'],
        },
        {
          validator: (rule, value, callback) => {
            if (!value && value != 0) {
              callback(new Error('请输入' + str))
            } else {
              const numericValue = parseFloat(value)
              if ((isShow && numericValue < 0) || (isShow && numericValue > 100)) {
                callback(new Error('进度必须在0到100之间'))
              } else {
                callback()
              }
            }
          },
          trigger: ['blur', 'change'],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped></style>
